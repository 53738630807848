import { ref, watch, computed } from '@vue/composition-api'

import userRepository from '@/repository/userRepository'
import store from '@/store'

export default function usersList() {
  const refUserListTable = ref(null)

  const tableColumns = [
    { key: 'actions', label: '' },
    { key: 'id', label: 'ИД', sortable: true },
    { key: 'avatar', label: 'Пользователь', sortable: true },
    { key: 'balance', label: 'Баланс', sortable: true },
    { key: 'email', label: 'Email', sortable: true },
    { key: 'lastLogin', label: 'Последний вход', sortable: true },
    { key: 'role', label: 'Группа пользователя', sortable: true },
    { key: 'createdAt', label: 'Создано', sortable: true },
    { key: 'updatedAt', label: 'Обновлено', sortable: true },
  ]

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    userRepository.userList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,

    })
      .then(response => {
        const { items, count } = response

        callback(items)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  const generateRandomVariant = () => {
    const randomNum = Math.floor(Math.random() * 5)
    switch (randomNum) {
      case 0:
        return 'primary'
      case 1:
        return 'warning'
      case 2:
        return 'success'
      case 3:
        return 'info'
      case 4:
        return 'danger'

      default:
        return 'primary'
    }
  }

  const resolveUserRoleVariant = role => {
    if (role === 'client') return 'primary'
    if (role === 'moderator') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'warning'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'client') return 'UserIcon'
    if (role === 'moderator') return 'SettingsIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const verifEmailVariant = verifEmail => {
    if (parseInt(verifEmail, 10) === 1) return 'success'
    return 'danger'
  }

  const verifEmailIcon = verifEmail => {
    if (parseInt(verifEmail, 10) === 1) return 'CheckIcon'
    return 'SlashIcon'
  }

  return {
    refUserListTable,
    tableColumns,
    dataMeta,
    fetchItems,

    perPage,
    totalItems,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    generateRandomVariant,
    resolveUserRoleIcon,
    resolveUserRoleVariant,
    verifEmailVariant,
    verifEmailIcon,
  }
}
